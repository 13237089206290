import { styled } from 'src/styled/Theme';
const RangePickerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 8px;
  .pick-range-calender {
    font-size: 20px;
  }
  .ant-picker-small {
    padding: 2px 7px 0 7px;
    height: 26px;
    max-width: 192px;
    border: 1px solid #efefef !important;
    border-radius: 0px 4px 4px 0px;
  }
  .ant-picker-panels {
    font-size: 12px;
  }
  .ant-picker {
    .ant-picker-input > input {
      font-size: 12px;
      min-width: 72px;
      text-align: center;
    }
    .anticon > svg {
      max-width: 12px;
    }
    .ant-picker-range-separator {
      padding: 0px 6px;
      .ant-picker-separator {
        max-width: 12px;
        color: #1a1a1a;
      }
    }
  }
  .ant-picker-input > input::placeholder {
    color: #dadada;
    font-size: 12px;
  }
`;
const HistoryTitle = styled.div`
  font-size: 16;
  color: '#000000';
  font-weight: 700;
`;
const RangePickerBox = styled.div`
  display: 'flex';
  flex-direction: 'row';
`;
const HistoryList = styled.div`
  max-height: 356px;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const HistoryIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  height: 18px;
`;
export { RangePickerWrapper, HistoryTitle, RangePickerBox, HistoryList, HistoryIcon };
