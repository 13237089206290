import styled from 'styled-components';

interface IcolorProject {
  bgColor?: string;
  width?: number;
  isAnimation?: boolean;
}

interface IopenTotal {
  isOpen?: boolean;
  isLoading?: boolean;
  height: number;
}

interface ItitleChart {
  bottom?: number;
}

interface ItextItemChart {
  isTitle?: boolean;
}

export const ColorProject = styled.div<IcolorProject>`
  background-color: ${(props) => props.bgColor};
  width: ${({ width }) => (width ? `${width}%` : `10px`)};
  height: 10px;
  animation: ${({ isAnimation }) => (isAnimation ? `expandWidth .7s` : `none`)};
  @keyframes expandWidth {
    0% {
      width: 10px;
    }
  }
`;

export const TooltipChart = styled.div`
  background: #fff;
  color: rgb(96, 96, 96);
  font-size: inherit;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 18px;
  padding: 10px;
  border: none;
  width: 100%;
`;

export const TitleTooltip = styled.div`
  color: #606060;
  font-size: 12px;
  text-align: center;
  background: #f5f5f5;
  margin: -10px -10px 0px -10px;
  padding: 4px 10px;
  border-radius: 5px 5px 0 0;
`;
export const TotalTime = styled.span`
  font-size: 12px;
  color: #8b8b8b;
`;
export const TimeHour = styled.span`
  font-size: 12px;
  color: #000000;
  font-weight: bold;
`;
export const TitleChart = styled.div<ItitleChart>`
  margin-bottom: ${({ bottom }) => (bottom ? `${bottom}px` : `0px`)};
  font-style: normal;
  font-weight: bold;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : `24px`)};
  line-height: ${({ lineHeight }) => (lineHeight ? `${lineHeight}px` : `33px`)};
  color: ${({ color }) => (color ? color : `#333333`)};
`;
export const TotalProject = styled.div<IopenTotal>`
  width: 100%;
  padding: 25px 25px 25px 15px;
  background: #ffffff;
  border-radius: 0 10px 10px 0;
  border-left: 1px solid #f5f5f5;
  height: ${({ height }) => (height ? `${height < 400 ? 400 : height}px` : `600px`)};
`;
export const StackBarChart = styled.div<IopenTotal>`
  padding: 0px 0px 20px 0px;
  border-radius: ${({ isOpen }) => (isOpen ? `10px` : `10px 0px 0px 10px`)};
  height: ${({ height }) => (height ? `${height < 600 ? 272 : 400}px` : `400px`)};
  background-color: #ffffff;
  text {
    color: #1a1a1a;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
`;
export const StackBarMiniChart = styled.div<IopenTotal>`
  padding: 25px 0px 75px 0px;
  border-radius: 10px 10px 0px 0px;
  height: ${({ height }) => (height ? `${height}px` : `300px`)};
  background-color: #ffffff;
`;
export const ItemProjectTime = styled.div`
  margin: 0 0 15px 0;
`;
export const ListItemProjectTime = styled.div<IopenTotal>`
  height: 500px;
  overflow-y: auto;
  height: ${({ height }) => (height ? `${height < 400 ? 300 : height - 100}px` : `500px`)};
`;
export const TextProjectTime = styled.div<ItextItemChart>`
  color: ${({ isTitle }) => (isTitle ? `#333333` : `#8B8B8B`)};
  font-size: ${({ isTitle }) => (isTitle ? `14px` : `12px`)};
`;
