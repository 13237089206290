import styled from 'styled-components';

interface Props {
  padding?: number;
  isHover?: boolean;
  radius?: number;
}
const Wrapper = styled.div<Props>`
  padding: ${(props) =>
    props.padding
      ? `${props.padding}px 24px`
      : props.paddingX && props.paddingY
      ? `${props.paddingY}px ${props.paddingX}px`
      : `16px 24px`};
  background-color: ${(props) => (props.bg ? props.bg : props.theme.colors.light[1])};
  border-radius: ${(props) => (props.radius ? `${props.radius}px` : `10px`)};
  width: 100%;
  &:hover {
    background-color: ${(props) =>
      props.isHover ? props.theme.colors.light[5] : props.theme.colors.light[1]};
  }
`;

const WrapperFavoriteProjects = styled(Wrapper)`
  padding: 16px 20px 20px;
`;

export { WrapperFavoriteProjects };
export default Wrapper;
