/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useRef, useState } from 'react';
import { DatePicker, Modal } from 'antd';
import { Image as I, EditHeatmap, HeatMap, Template } from 'src/styled/Component';
import { Flex } from 'src/styled/Layout';
import { icons, imgs } from '@src/static';
import { FORMAT_DAY_MONTH_YEAR } from '@src/common/constants';
import moment from 'moment';
import dayjs from 'dayjs';
import { IAppState } from '@src/interface/IAppState';
import { useDispatch, useSelector } from 'react-redux';
import {
  editHistoryHeatmap,
  setDateMood,
  setLoadingEditHeatmap
} from '@src/components/Heatmap/form/formAction';
import HeatmapItem from '@src/components/Heatmap/formarea/formItem';
import { IDataHeatmapItem } from '@src/interface/IDataHeatmap';
import { editDataHeatmap } from './editHeatmapAction';
import { loadDrafts, saveHeatmap } from '@src/utils/function';
import isEqual from 'lodash/isEqual';
import { IFormItemState } from '@src/interface/IFormState';
import FavoriteProjectsModal from '@src/components/Modal/FavoriteProjectsModal';
import Message from 'antd/lib/message';

interface Props {
  isShow: boolean;
  onCancel: () => void;
  handleOpenModalTemplate: (activity: string) => void;
  handleOpenModalAddProject: () => void;
}
const ModalEditHeatmap: React.FC<Props> = (props) => {
  const { isShow, onCancel, handleOpenModalTemplate, handleOpenModalAddProject } = props;
  const editHeatmapState = useSelector((state: IAppState) => state.editHeatmapState);
  const formState = useSelector((state: IAppState) => state.formState);
  const { loadingEditHeatmap } = formState;
  const dispatch = useDispatch();
  const [date, setDate] = useState(moment());
  const [listProjects, setListProjects] = useState<any>([]);
  const [isDatepickerOpening, setIsDatepickerOpening] = useState(false);
  const [projectEdit, setProjectEdit] = useState<IFormItemState | null>(null);
  const [openModalEditProject, setModalEditProject] = useState(false);
  const [disableSubmitModal, setDisableSubmitModal] = useState(false);

  const datepickerRef = useRef(null);
  const [modal, contextHolder] = Modal.useModal();

  const handleCancel = () => {
    onCancel();
  };

  const onChange = (date: moment.Moment | null) => {
    setDate(date || moment());
    setIsDatepickerOpening(false);
    dispatch(
      editDataHeatmap({
        dateEdit: date,
        itemsEdit: editHeatmapState.itemsEdit
      })
    );
    saveHeatmap({
      id: dayjs(editHeatmapState.dateLocal).format('DD-MM-YYYY'),
      date: date,
      items: editHeatmapState.itemsEdit
    });
  };

  const disabledDate = (current: moment.Moment) => {
    return current && moment(current, 'YYYY-MM-DD') > moment(new Date(), 'YYYY-MM-DD');
  };

  const handleActionHeatmap = (dateEdit: string, itemsEdit: IDataHeatmapItem[]) => {
    dispatch(editDataHeatmap({ dateEdit, itemsEdit }));
    saveHeatmap({
      id: dayjs(editHeatmapState.dateLocal).format('DD-MM-YYYY'),
      date: dateEdit,
      items: itemsEdit
    });
  };

  const onSave = () => {
    dispatch(setLoadingEditHeatmap(true));
    dispatch(setDateMood(date));
    if (editHeatmapState && editHeatmapState.dateLocal) {
      const dataEdit = {
        from_date: editHeatmapState.dateLocal,
        to_date: date.format(),
        items: editHeatmapState.itemsEdit
      };
      dispatch(editHistoryHeatmap(dataEdit));
    }
  };

  const handleDeleteProject = (project) => {
    const newArr = editHeatmapState.itemsEdit.filter(
      (item) => item.project_id !== project.project_id
    );
    handleActionHeatmap(editHeatmapState.dateEdit, [...newArr]);
  };
  const onCancelConfirmModal = () => {
    return () => {
      const draft = loadDrafts('HEATMAP');
      if (draft[dayjs(editHeatmapState.dateLocal).format('DD-MM-YYYY')]) {
        delete draft[dayjs(editHeatmapState.dateLocal).format('DD-MM-YYYY')];
        localStorage.setItem('HEATMAP', JSON.stringify(draft));
      }
    };
  };

  const onOkConfirmModal = () => {
    const drafts = loadDrafts('HEATMAP');
    return () => {
      dispatch(
        editDataHeatmap({
          dateEdit: drafts[dayjs(editHeatmapState.dateLocal).format('DD-MM-YYYY')]?.date,
          itemsEdit: drafts[dayjs(editHeatmapState.dateLocal).format('DD-MM-YYYY')]?.items || []
        })
      );
    };
  };

  const configConfirmedModal = {
    title: (
      <Template.Text fontSize="16" fontWeight="700">
        You have a record of pending edits
      </Template.Text>
    ),
    rootPrefixCls: 'ant',
    prefixCls: 'ant-modal',
    content: (
      <>
        <Template.Text>
          Click OK to recover these edits. Edits will be deleted if you click Cancel
        </Template.Text>
      </>
    ),
    cancelText: 'Cancel',
    okText: 'OK',
    onCancel: onCancelConfirmModal(),
    onOk: onOkConfirmModal()
  };

  const handleEditProject = (project: IFormItemState) => {
    setModalEditProject(true);
    setProjectEdit(project);
  };

  const closeModalEditProject = () => {
    setModalEditProject(false);
  };

  const onEditProject = async (projectsSelected) => {
    if (!projectEdit) return;
    const projectNeedEdit = [projectEdit];
    const listDataHeatmapId = editHeatmapState.itemsEdit.map((item) => item.project_id);
    const temp = { ...editHeatmapState };
    setDisableSubmitModal(true);
    if (!projectsSelected || !projectsSelected.length) {
      setDisableSubmitModal(false);
      closeModalEditProject();
      return;
    }
    if (!projectNeedEdit || !projectNeedEdit.length) {
      Message.error('No projects.');
      setDisableSubmitModal(false);
      return;
    }
    const listProjectsNeededEdit = projectNeedEdit.reverse();
    if (listProjectsNeededEdit) {
      listProjectsNeededEdit.forEach((project) => {
        if (listDataHeatmapId.includes(project.project_id)) {
          const index = editHeatmapState.itemsEdit.findIndex(
            (item) => item.project_id === project.project_id
          );
          if (index !== -1) {
            temp.itemsEdit[index] = {
              ...temp.itemsEdit[index],
              project_id: projectsSelected[0]?.id,
              project_name: projectsSelected[0]?.name,
              project_color: projectsSelected[0]?.color
            };
          }
        }
      });
      dispatch(editDataHeatmap({ ...temp }));
      saveHeatmap({
        id: dayjs(editHeatmapState.dateLocal).format('DD-MM-YYYY'),
        date: temp.dateEdit,
        items: temp.itemsEdit
      });
      setDisableSubmitModal(false);
      closeModalEditProject();
    }
  };

  useEffect(() => {
    setListProjects(editHeatmapState.itemsEdit || []);
  }, [editHeatmapState]);

  useEffect(() => {
    if (editHeatmapState.dateEdit) {
      setDate(moment(new Date(editHeatmapState.dateEdit)));
      return;
    }
  }, [editHeatmapState.dateEdit]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datepickerRef.current && !(datepickerRef.current as any).contains(event.target)) {
        setIsDatepickerOpening(false);
      }
    };
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [datepickerRef]);

  useEffect(() => {
    const drafts = loadDrafts('HEATMAP');
    const draftEditId = drafts[dayjs(editHeatmapState.dateLocal).format('DD-MM-YYYY')];
    const draftEdit = {
      date: dayjs(draftEditId?.date).format('DD-MM-YYYY'),
      items: draftEditId?.items
    };
    const currentEdit = {
      date: dayjs(editHeatmapState?.dateEdit).format('DD-MM-YYYY'),
      items: editHeatmapState?.itemsEdit
    };
    if (isEqual(draftEdit, currentEdit)) {
      delete drafts[dayjs(editHeatmapState.dateLocal).format('DD-MM-YYYY')];
      localStorage.setItem('HEATMAP', JSON.stringify(drafts));
    }
  }, []);

  useEffect(() => {
    const drafts = loadDrafts('HEATMAP');
    const draftEditId = drafts[dayjs(editHeatmapState.dateLocal).format('DD-MM-YYYY')];
    if (draftEditId) {
      setTimeout(() => {
        modal.confirm(configConfirmedModal);
      }, 500);
    }
  }, []);

  return (
    <Modal
      wrapClassName="modal-wrap-edit-heatmap"
      className="modal-edit-heatmap"
      visible={isShow}
      footer={null}
      width={435}
      centered
      onCancel={handleCancel}
      maskClosable={false}
      bodyStyle={{
        height: '100%'
      }}>
      <Flex
        full
        row
        style={{
          padding: '16px 24px',
          position: 'relative',
          backgroundColor: '#FFF',
          borderTopRightRadius: '6px',
          borderTopLeftRadius: '6px'
        }}>
        <EditHeatmap.Text fontSize="16" fontWeight="700">
          Edit heatmap
        </EditHeatmap.Text>
        <EditHeatmap.ButtonClose onClick={handleCancel}>
          <I.Image src={icons.IconClose} alt="iconClose" w={24} h={24} />
        </EditHeatmap.ButtonClose>
      </Flex>
      <EditHeatmap.WrapperBodyModal>
        <Flex
          row
          full
          style={{
            gap: '4px'
          }}>
          <EditHeatmap.DatePickerBox>
            <I.Image src={imgs.Calender} alt="calendar" w={34} h={34} style={{ marginTop: 0 }} />
            <div ref={datepickerRef} className="w-full">
              {/* @ts-ignore */}
              <DatePicker
                className={'heatmap-picker-area'}
                dropdownClassName={'popup-date-picker'}
                bordered={false}
                suffixIcon={
                  <I.Image
                    src={icons.IconChevronDown}
                    alt="down"
                    w={18}
                    h={18}
                    rotate={isDatepickerOpening}
                    transition="all 300ms"
                    style={{ marginTop: 0 }}
                  />
                }
                inputReadOnly={true}
                allowClear={false}
                showToday={false}
                format={FORMAT_DAY_MONTH_YEAR}
                name={'date'}
                style={{ paddingLeft: 10, width: '100%' }}
                value={date}
                onChange={onChange}
                disabledDate={disabledDate}
                getPopupContainer={(trigger: any) => {
                  return trigger.parentNode;
                }}
                open={isDatepickerOpening}
                onClick={() => setIsDatepickerOpening(!isDatepickerOpening)}
              />
            </div>
          </EditHeatmap.DatePickerBox>
          <HeatMap.Button
            bgColor="#FFF"
            border="1px solid #242424"
            padding="9px 24px"
            onClick={handleOpenModalAddProject}>
            Add
          </HeatMap.Button>
        </Flex>
        {!listProjects || !listProjects.length ? (
          <HeatMap.ContainerDefault>
            <HeatMap.WrapperDefault style={{ height: '176px' }}>
              <HeatMap.Text
                color="#8A8A8A"
                fontSize="13.5"
                lineHeight="22px"
                style={{ marginBottom: '6px' }}>
                In the section above, add projects you are currently working on. Then, click
                &ldquo;Post heatmap&ldquo; under the project name
              </HeatMap.Text>
              <I.Image
                src={imgs.DefaultPostHeatmap}
                alt="post heatmap"
                w={262}
                h={84}
                style={{ marginTop: 0 }}
              />
            </HeatMap.WrapperDefault>
          </HeatMap.ContainerDefault>
        ) : (
          <EditHeatmap.WrapperFormHeatmap
            style={{
              marginTop: 0
            }}>
            {listProjects.map((item) => {
              return (
                <HeatmapItem
                  project={item}
                  key={item.project_id}
                  handleOpenModalTemplate={handleOpenModalTemplate}
                  date={editHeatmapState.dateEdit}
                  items={editHeatmapState.itemsEdit}
                  handleActionHeatmap={handleActionHeatmap}
                  handleDeleteProject={handleDeleteProject}
                  editProject
                  handleEditProject={handleEditProject}
                />
              );
            })}
          </EditHeatmap.WrapperFormHeatmap>
        )}
        <Flex row full flexend style={{ gap: '6px' }}>
          <EditHeatmap.Button onClick={handleCancel}>Cancel</EditHeatmap.Button>
          <EditHeatmap.Button
            color="#FFF"
            bgColor="#1A1A1A"
            onClick={onSave}
            disable={
              !listProjects ||
              !listProjects.length ||
              listProjects.findIndex(
                (item) => !(item.hours > 0 && item.hours < 24) || !item.content
              ) >= 0 ||
              loadingEditHeatmap
            }>
            Update
          </EditHeatmap.Button>
        </Flex>
      </EditHeatmap.WrapperBodyModal>
      {contextHolder}
      {openModalEditProject && (
        <FavoriteProjectsModal
          title="Choose which project to edit"
          isOpen={openModalEditProject}
          closeModal={closeModalEditProject}
          disable={disableSubmitModal}
          onSubmit={onEditProject}
          listDefaultProjects={[]}
          listHiddenProjects={editHeatmapState.itemsEdit.map((item) => item.project_id)}
          singleSelect
        />
      )}
    </Modal>
  );
};

export default React.memo(ModalEditHeatmap);
