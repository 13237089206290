import { IInforFavoriteProject } from '@src/interface/IFavoriteProjectsState';
import { imgs } from '@src/static';
import { LengendFavoriteProject } from '@src/styled/Component/Lengend';
import { FC } from 'react';
import { Card as C, Button as B, Image as I, Text as T } from 'src/styled/Component';
const { RemoveProject } = imgs;

interface Props {
  project: IInforFavoriteProject;
  onRemove(): void;
}
const FavoriteProjectItem: FC<Props> = ({ project, onRemove }) => {
  return (
    <C.FavoriteProjectItemCard key={project.id + 20}>
      <div className="flex center">
        <LengendFavoriteProject color={project.color} mr={8} />
        <p>{`[${project.id}] ${project.name}`}</p>
      </div>
      <B.RemoveFavoriteProjectItem onClick={onRemove} width={16} height={16} radius={2} show={true}>
        <I.Image src={RemoveProject} w={8} h={8} mrTop="0px" alt="remove project" />
      </B.RemoveFavoriteProjectItem>
    </C.FavoriteProjectItemCard>
  );
};

export default FavoriteProjectItem;
