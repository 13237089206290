import { IresourceProject, IinforProject } from 'src/interface/IHeatmapState';
import dayjs from 'dayjs';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import { defaultColorById } from 'src/common/utils';

export const getProjectList = (data: Array<IinforProject>) => {
  const listProject: string[] = [];
  const colorProject = {};
  const idProject = {};
  const fullNameProject = {};
  if (data && data.length) {
    data.forEach((element) => {
      listProject.push(String(element.id));
      colorProject[element.id] = element.color || defaultColorById(element.id);
      idProject[element.id] = element.id;
      fullNameProject[element.id] = element.name;
    });
  }
  return {
    listProject,
    colorProject,
    idProject,
    fullnameProject: fullNameProject
  };
};

const createListArrayDate = (timeStart: string, timeEnd: string, dataFilter: Array<any>) => {
  let timeFirst = dayjs(timeStart);
  const timeSecond = dayjs(timeEnd);
  const Arr: Array<string> = [];
  const finalData: Array<any> = [];
  if (timeStart && timeEnd) {
    do {
      Arr.push(dayjs(timeFirst).format('MM/DD/YYYY'));
      timeFirst = dayjs(timeFirst).add(1, 'days');
    } while (timeFirst <= timeSecond);
    Arr.forEach((item) => {
      const itemFilter = dataFilter.find((value) => {
        return dayjs(value.day).format('MM/DD/YYYY') === item;
      });
      if (itemFilter) {
        itemFilter.day = dayjs(itemFilter.day).format('MM/DD/YYYY');
        finalData.push(itemFilter);
      } else {
        const date = dayjs(item, 'MM/DD/YYYY').format('DD');
        finalData.push({ day: item, date: date });
      }
    });
  }
  return finalData;
};

export const handleDataResource = (
  data: IresourceProject[],
  timeStart: string,
  timeEnd: string
) => {
  const resultItem = {};
  const resultData: Array<Record<string, any>> = [];
  if (data && data.length) {
    data.forEach((item: any) => {
      const current = resultItem[item.date] || {};
      current['day'] = item.date;
      current[item.project.id] = (current[item.project.id] || 0) + item.hours;
      current['date'] = dayjs(item.date).format('DD');
      resultData.push(current);
      resultItem[item.date] = current;
    });
  }

  const dataFilter = uniqWith(resultData.reverse(), isEqual);
  return createListArrayDate(timeStart, timeEnd, dataFilter);
};
