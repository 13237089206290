import React from 'react';
import dayjs from 'dayjs';

import { IdataWorkingDay } from 'src/interface/IHeatmapState';
import {
  ColorProject,
  TitleTooltip,
  TotalTime,
  TimeHour,
  TooltipChart
} from 'src/styled/Component/Chart';
import { Flex } from 'src/styled/Layout/index';

import { FORMAT_DAY_MONTH_YEAR } from 'src/common/constants';

interface Props {
  dataWorking: Array<IdataWorkingDay>;
  day: string | number;
  hour: number;
  color: any; // eslint-disable-line
  idProject: any; // eslint-disable-line
}

const FormatTooltip: React.FC<Props> = (props: Props) => {
  const { dataWorking, day, hour, color, idProject } = props;
  const dateFormat = dayjs(day, 'MM/DD/YYYY').format(FORMAT_DAY_MONTH_YEAR);
  const getColor = (id: string) => {
    return color[id];
  };

  return (
    <TooltipChart>
      <TitleTooltip>{dateFormat}</TitleTooltip>
      <TotalTime>Total</TotalTime>
      <TimeHour> : {hour} hrs</TimeHour>
      {dataWorking && dataWorking.length ? (
        dataWorking.map((el: IdataWorkingDay) => (
          <div key={el.nameProject}>
            <Flex flexStart algin>
              <ColorProject bgColor={getColor(el.nameProject)} />
              <span>
                [{idProject[`${el.nameProject}`]}] {el.hour} hrs
              </span>
            </Flex>
          </div>
        ))
      ) : (
        <></>
      )}
    </TooltipChart>
  );
};

export default FormatTooltip;
