import { styled } from '@src/styled/Theme';

const Modal = styled.div`
  background-color: ${(props) => props.color};
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  margin-right: ${({ mr }) => (mr ? `${mr}px` : '10px')};
  display: inline-block;
`;

const InputSearchContainer = styled.div`
  width: 100%;
  outline: solid 1px #1a1a1a;
  outline-offset: -1px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  margin-bottom: 6px;
`;

const InputSearchProject = styled.input`
  flex-grow: 1;
  padding: 0;
  border: none;
  outline: none;
  line-height: 18.2px;
  height: 18px;
  font-size: 14px;
  color: #1a1a1a;
  :focus {
    outline: none;
  }
  ::placeholder {
    line-height: 18.2px;
    height: 18px;
    font-size: 14px;
  }
`;

const ModalContent = styled.div`
  height: 361px;
  display: flex;
  flex-direction: column;
`;

const FavoriteProjectsContainer = styled.div`
  padding: 6px;
  margin-bottom: 10px;
  outline: solid 1px #f2f2f2;
  outline-offset: -1px;
  border-radius: 4px;
  max-height: 70px;
  overflow-y: auto;
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
`;

const AllProjectsContainer = styled.div`
  flex-grow: 1;
  padding: 12px;
  background-color: #f9f9f9;
  width: 100%;
  border-radius: 4px;
  height: 281px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const EmptyProjectOptions = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 281px;
  align-items: center;
  justify-content: center;
  p {
    margin: 0;
    line-height: 14px;
    font-size: 14px;
  }
`;

export {
  Modal,
  InputSearchContainer,
  InputSearchProject,
  FavoriteProjectsContainer,
  AllProjectsContainer,
  EmptyProjectOptions,
  ModalContent
};
