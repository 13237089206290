import { FC } from 'react';
import { IinforProject } from '@src/interface/IHeatmapState';
import { Card as C, Button as B, Image as I, Text as T } from 'src/styled/Component';
import { imgs } from '@src/static';
import { LengendFavoriteProject } from '@src/styled/Component/Lengend';
const { Check } = imgs;

interface Props {
  project: IinforProject;
  checked: boolean;
  changeCheckbox(): void;
}

const ProjectOption: FC<Props> = ({ project, changeCheckbox, checked }) => {
  return (
    <C.ProjectOptionCard>
      <label className="project-checkbox-container">
        <input onChange={changeCheckbox} type="checkbox" value={project.id} checked={checked} />
        <div className="project-checkbox-checkmark">
          <I.Image src={Check} w={11.56} h={11.56} mrTop="0px" />
        </div>
        <div className="flex center">
          <LengendFavoriteProject color={project.color} mr={8} />
          <p>{`[${project.id}] ${project.name}`}</p>
        </div>
      </label>
    </C.ProjectOptionCard>
  );
};

export default ProjectOption;
