import { styled } from '@src/styled/Theme';

interface Props {
  color: string;
  size: number;
  mr?: number;
}

export const Lengend = styled.div<Props>`
  background-color: ${(props) => props.color};
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  margin-right: ${({ mr }) => (mr ? `${mr}px` : '10px')};
  display: inline-block;
`;

export const LengendFavoriteProject = styled(Lengend)`
  width: 8px;
  height: 14px;
  border-radius: 2px;
`;
