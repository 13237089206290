/* eslint-disable @typescript-eslint/ban-ts-comment */
import { icons } from '@src/static';
import { HeatMap } from '@src/styled/Component';
import React, { useEffect, useState } from 'react';
import { Image as I } from 'src/styled/Component';
import InputNumber from 'antd/lib/input-number';
import Input from 'antd/lib/input';
import { IAppState } from '@src/interface/IAppState';
import { useSelector } from 'react-redux';
import { IFormItemState } from '@src/interface/IFormState';
import Form from 'antd/lib/form';
import ReactTooltip from 'react-tooltip';
import { ITemplate } from '@src/interface/ITemplate';
import { IDataHeatmapItem } from '@src/interface/IDataHeatmap';

interface Props {
  project: IFormItemState;
  handleOpenModalTemplate: (activity: string) => void;
  date: string;
  items: IDataHeatmapItem[];
  editProject?: boolean;
  handleActionHeatmap: (date: string, items: IDataHeatmapItem[]) => void;
  handleDeleteProject: (project) => void;
  handleEditProject?: (project: IFormItemState) => void;
}

const HeatmapItem: React.FC<Props> = ({
  project,
  handleOpenModalTemplate,
  date,
  items,
  editProject = false,
  handleActionHeatmap,
  handleDeleteProject,
  handleEditProject
}) => {
  const { data: templates } = useSelector((state: IAppState) => state.templateState);
  const [errorHour, setErrorHour] = useState<string | null>(null);
  const [errorActivity, setErrorActivity] = useState<string | null>(null);
  const [valueHour, setValueHour] = useState<number | null>(null);
  const [activity, setActivity] = useState('');
  const [template, setTemplate] = useState<ITemplate>({ id: null, name: '', content: '' });
  const [isCheckHour, setIsCheckHour] = useState(false);
  const [isCheckActivity, setIsCheckActivity] = useState(false);

  const [form] = Form.useForm();

  const getHourErrMessage = (hour: number | string | undefined | null) => {
    if (hour === null || hour === '') {
      return 'Hour field is required! Please try again';
    } else if (typeof hour !== 'number' && hour !== '0') {
      return 'Hour field should be a number! Please try again';
    } else if (hour === '0' || hour <= 0 || hour >= 24) {
      return 'Hour field should be greater than 0 and less than 24';
    } else return null;
  };

  const activityErrMessage = 'Activity field is required! Please try again';

  const handleAddHourClick = (value: number) => {
    setValueHour(value);
    setIsCheckHour(true);
  };

  const handleAddHourClickHalf = (value: number) => {
    const temp = (valueHour as number) + value;
    setValueHour(temp);
    setIsCheckHour(true);
  };

  const handleInputChange = (value) => {
    setIsCheckHour(true);
    setValueHour(value === 0 ? '0' : value);
  };

  const handleChoseTemplate = (template: ITemplate) => {
    setActivity(template.content);
    setTemplate(template);
    setIsCheckActivity(true);
  };

  const handleChangeActivity = (e: { target: { value: React.SetStateAction<string> } }) => {
    setActivity(e.target.value);
    setIsCheckActivity(true);
  };

  useEffect(() => {
    if (project) {
      setActivity(project.content);
      setIsCheckActivity(false);
      setValueHour(project.hours);
      setIsCheckHour(false);
    }
  }, [project]);

  useEffect(() => {
    if (isCheckActivity || isCheckHour) {
      const temp = { date, items: [...items] };
      const index = temp.items.findIndex((item) => item.project_id === project.project_id);
      if (index >= 0) {
        temp.items[index] = {
          ...temp.items[index],
          content: activity,
          hours: (valueHour ? valueHour : null) as number
        };
      }
      handleActionHeatmap(temp.date, temp.items);
    }
  }, [valueHour, activity]);

  useEffect(() => {
    if (isCheckHour) setErrorHour(getHourErrMessage(valueHour));
  }, [valueHour]);

  useEffect(() => {
    if (isCheckActivity) setErrorActivity(activity ? null : activityErrMessage);
    if (activity === '') {
      setTemplate({ id: null, name: '', content: '' });
    }
  }, [activity]);

  return (
    <HeatMap.ContainerItem>
      <Form form={form}>
        <HeatMap.HeaderItem style={{ marginBottom: '3px' }}>
          <HeatMap.TitleBox
            hover={editProject}
            onClick={
              editProject ? () => handleEditProject && handleEditProject(project) : undefined
            }>
            <HeatMap.ColorProject color={project.project_color} />
            <HeatMap.Text lineHeight="18.2px" fontWeight="600">
              {`[${project.project_id}] ${project.project_name}`}
            </HeatMap.Text>
            {editProject && (
              <I.Image
                src={icons.IconEditTem}
                alt="icon edit"
                w={18}
                h={18}
                style={{ marginTop: 0 }}
              />
            )}
          </HeatMap.TitleBox>
          <div
            style={{ cursor: 'pointer', width: '20px', height: '20px' }}
            onClick={() => handleDeleteProject(project)}>
            <I.Image src={icons.IconClose} alt="iconClose" w={20} h={20} />
          </div>
        </HeatMap.HeaderItem>
        <HeatMap.BodyForm>
          <HeatMap.Text require color="#6D6D6D" fontSize="13">
            Hours / day
          </HeatMap.Text>
          <HeatMap.BoxAddHour>
            {Array.from({ length: 8 }).map((_, index) => (
              <HeatMap.BoxItemHour key={index} onClick={() => handleAddHourClick(index + 1)}>
                {index + 1}
              </HeatMap.BoxItemHour>
            ))}
            <HeatMap.BoxItemHour onClick={() => handleAddHourClickHalf(0.5)}>
              +0.5
            </HeatMap.BoxItemHour>
          </HeatMap.BoxAddHour>
          <Form.Item
            name={'hour'}
            style={{ width: '100%', marginBottom: '0' }}
            validateStatus={errorHour ? 'error' : ''}
            help={errorHour}>
            <HeatMap.BoxInputAddHours>
              <InputNumber
                className="input-add-hours"
                value={valueHour as number}
                name={'hour'}
                onChange={handleInputChange}
                defaultValue={null as any}
              />
              <I.Image
                src={icons.IconClose}
                alt="iconClose"
                w={16}
                h={16}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setValueHour(null);
                  setIsCheckHour(true);
                }}
              />
            </HeatMap.BoxInputAddHours>
          </Form.Item>
          <HeatMap.Text require color="#6D6D6D" fontSize="13" style={{ marginTop: '4px' }}>
            Activity / Purpose / Mission
          </HeatMap.Text>
          <HeatMap.BoxTemplate>
            <HeatMap.BoxTemplateContainer>
              {templates.map((item) => (
                <HeatMap.TemplateItem
                  key={item.id}
                  onClick={() => handleChoseTemplate(item)}
                  active={template.id === item.id}>
                  <I.Image
                    src={icons.IconTemplateHeatMap}
                    alt="icon file"
                    w={13}
                    h={13}
                    style={{ marginTop: 0 }}
                  />
                  <p>{item.name}</p>
                </HeatMap.TemplateItem>
              ))}
            </HeatMap.BoxTemplateContainer>
          </HeatMap.BoxTemplate>
          <Form.Item
            name={'activity'}
            validateStatus={errorActivity ? 'error' : ''}
            help={errorActivity}
            style={{ width: '100%', marginBottom: '0' }}>
            <HeatMap.BoxInputArea>
              <HeatMap.ImgClear
                src={icons.IconClose}
                alt="iconClose"
                w={16}
                h={16}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setActivity('');
                  setTemplate({ id: null, name: '', content: '' });
                  setIsCheckActivity(true);
                }}
              />
              <Input.TextArea
                style={{ fontSize: 16 }}
                placeholder={'Write your task'}
                rows={4}
                name={'activity'}
                className="text-area-heatmap"
                value={activity}
                onChange={handleChangeActivity}
              />
              <HeatMap.BoxSaveTemplate>
                <div
                  data-tip="Save as my template"
                  data-for={`save_template_${project.project_id}`}
                  style={{ width: '16px', height: '16px' }}>
                  <I.Image
                    src={icons.IconFileDownload}
                    alt="icon down load"
                    w={16}
                    h={16}
                    style={{ marginTop: 0 }}
                    onClick={() => handleOpenModalTemplate(activity)}
                  />
                </div>
              </HeatMap.BoxSaveTemplate>
            </HeatMap.BoxInputArea>
          </Form.Item>
        </HeatMap.BodyForm>
      </Form>
      {/* @ts-ignore */}
      <ReactTooltip
        id={`save_template_${project.project_id}`}
        place="top"
        effect="float"
        className="save_tmp_tooltip"
        backgroundColor="rgba(26, 26, 26, 0.73)"
        arrowColor="none"
        padding="4px"
        offset={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0
        }}
      />
    </HeatMap.ContainerItem>
  );
};

export default HeatmapItem;
