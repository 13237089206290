import { FC, useEffect, useState } from 'react';
import Modal from 'antd/lib/modal';
import { Button as B, Image as I, Text as T } from 'src/styled/Component';
import {
  InputSearchContainer,
  InputSearchProject,
  FavoriteProjectsContainer,
  AllProjectsContainer,
  EmptyProjectOptions,
  ModalContent
} from '@src/styled/Component/Modal';
import { imgs } from '@src/static';
import { Flex } from '@src/styled/Layout';
import { useSelector } from 'react-redux';
import { IAppState } from '@src/interface/IAppState';
import FavoriteProjectItem from './FavoriteProjectItem';
import ProjectOption from './ProjectOption';
import { IInforFavoriteProject } from '@src/interface/IFavoriteProjectsState';
const { SearchProject, RemoveProject, CloseModal, EmptyProjects } = imgs;

interface Props {
  title: string;
  isOpen: boolean;
  disable: boolean;
  closeModal(): void;
  onSubmit: (listProjects: any) => void;
  listDefaultProjects: IInforFavoriteProject[];
  listHiddenProjects: number[];
  singleSelect?: boolean;
}

const FavoriteProjectsModal: FC<Props> = ({
  title,
  isOpen,
  disable,
  closeModal,
  onSubmit,
  listDefaultProjects,
  listHiddenProjects,
  singleSelect = false
}) => {
  const dataHeatmap = useSelector((state: IAppState) => state.heatmapState);
  const [searchValue, setSearchValue] = useState('');
  const [allProjects, setAllProjects] = useState<any>([]);
  const [listFavoriteProjects, setListFavoriteProjects] = useState<any>([]);
  const removeProject = (project) => {
    const temp = listFavoriteProjects.filter((item: any) => item.id !== project.id);
    setListFavoriteProjects([...temp]);
  };
  const changeCheckbox = (project) => {
    let temp = listFavoriteProjects;

    if (singleSelect) {
      if (temp.map((item: any) => item.id).includes(project.id)) {
        setListFavoriteProjects([]);
      } else {
        setListFavoriteProjects([project]);
      }
    } else {
      if (temp.map((item) => item.id).includes(project.id)) {
        temp = temp.filter((item) => item.id !== project.id);
      } else {
        temp.unshift(project);
      }
      setListFavoriteProjects([...temp]);
    }
  };
  const checkExisted = (project, listFavorite) => {
    const listFavoriteId = listFavorite.map((item) => item.id);
    if (listFavoriteId.includes(project.id)) return true;
    return false;
  };

  useEffect(() => {
    const allProjectsData = dataHeatmap.data || [];
    const temp = allProjectsData.filter((item) => !listHiddenProjects.includes(item.id));
    setAllProjects([
      ...temp.filter((project) => project.name.toLowerCase().includes(searchValue.toLowerCase()))
    ]);
  }, [searchValue]);

  useEffect(() => {
    const allProjectsData = dataHeatmap.data || [];
    const temp = allProjectsData.filter((item) => !listHiddenProjects.includes(item.id));
    setAllProjects([...temp]);
  }, [dataHeatmap]);

  useEffect(() => {
    const temp = listDefaultProjects;
    setListFavoriteProjects([...temp]);
  }, [listDefaultProjects]);

  return (
    <Modal
      wrapClassName="modal-wrap-favorite-projects"
      visible={isOpen}
      centered
      maskClosable={false}
      closable={false}
      footer={null}
      width={536}
      className="favorite-projects-modal">
      <B.CloseFavoriteProjectsModal
        w={24}
        h={24}
        onClick={() => {
          closeModal();
        }}>
        <I.Image src={CloseModal} w={10} h={10} mrTop="0" />
      </B.CloseFavoriteProjectsModal>
      <T.FavoriteProjectsModalTitle>{title}</T.FavoriteProjectsModalTitle>
      <InputSearchContainer>
        <I.Image src={SearchProject} w={18} h={18} mrTop="0px" alt="search project" />
        <InputSearchProject
          placeholder="Input project name"
          value={searchValue}
          onChange={(e) => {
            e.preventDefault();
            setSearchValue(e.target.value);
          }}
        />
        {searchValue ? (
          <B.RemoveFavoriteProject
            onClick={() => {
              setSearchValue('');
            }}
            width={16}
            height={16}
            radius={0}
            show={true}
            bg="#fff">
            <I.Image src={RemoveProject} w={8} h={8} mrTop="0px" alt="clear project name" />
          </B.RemoveFavoriteProject>
        ) : null}
      </InputSearchContainer>
      <ModalContent>
        {listFavoriteProjects && listFavoriteProjects.length ? (
          <FavoriteProjectsContainer>
            {listFavoriteProjects.map((project) => (
              <FavoriteProjectItem
                key={project.id}
                project={project}
                onRemove={() => removeProject(project)}
              />
            ))}
          </FavoriteProjectsContainer>
        ) : (
          <></>
        )}
        <AllProjectsContainer>
          {allProjects && allProjects.length ? (
            allProjects.map((project) => (
              <ProjectOption
                key={project.id}
                project={project}
                checked={checkExisted(project, listFavoriteProjects)}
                changeCheckbox={() => changeCheckbox(project)}
              />
            ))
          ) : (
            <EmptyProjectOptions>
              <I.Image src={EmptyProjects} w={217} h={186} mrTop="0" />
              <p>No data</p>
            </EmptyProjectOptions>
          )}
        </AllProjectsContainer>
      </ModalContent>

      <Flex jusend>
        <B.DefaultButton
          onClick={() => onSubmit(listFavoriteProjects)}
          mt={16}
          br={4}
          padding="10px 24px"
          color="#fff"
          bg="#1a1a1a"
          fz={14}
          fw={400}
          lineHeight={14}
          disable={disable}>
          Done
        </B.DefaultButton>
      </Flex>
    </Modal>
  );
};

export default FavoriteProjectsModal;
