import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import List from 'antd/lib/list';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Menu from 'antd/lib/menu';
import Dropdown from 'antd/lib/dropdown';
import message from 'antd/lib/message';
import dayjs from 'dayjs';
import { defaultColorById, handleBreakline } from 'src/common/utils';
import { Wrapper } from 'src/styled/Layout';
import { Image as I, Label as L, Text as T } from 'src/styled/Component';
import { IHistoryItemState, IresourceProject } from 'src/interface/IHeatmapState';
import { icons } from '@src/static';
import seeMoreImage from 'src/assets/images/see-more.png';
import { HistoryIcon, HistoryList } from '@src/styled/Component/RangePickerWrapper';
import { activeTemplateAction, updateDataHeatmap } from '@src/components/Heatmap/dataHeatmapAction';
import { IAppState } from '@src/interface/IAppState';
import { setEditFail } from '../form/formAction';
import ModalEditHeatmap from '@src/components/Modal/ModalEditHeatmap';
import FavoriteProjectsModal from '@src/components/Modal/FavoriteProjectsModal';

import {
  editDataHeatmap,
  setDateLocalEdit
} from '@src/components/Modal/ModalEditHeatmap/editHeatmapAction';
import { saveHeatmap } from '@src/utils/function';

interface Props {
  historyList: IresourceProject[];
  handleDelete: (data: IHistoryItemState[], deleteAllProjects: boolean) => void;
  handleOpenModalTemplate: (activity: string) => void;
}

export const handleData = (historyList: IresourceProject[]): ReturnType<any> => {
  return historyList
    .sort((iteml: IresourceProject, itemr: IresourceProject) => {
      const datel = new Date(iteml.date).getTime();
      const dater = new Date(itemr.date).getTime();
      return dater - datel;
    })
    .map((item: IresourceProject) => {
      const newData: IHistoryItemState = {
        date: item.date,
        hour: item.hours,
        id: item.project.id,
        activity: item.content,
        color: item.project.color || defaultColorById(item.project.id),
        project: item.project.name,
        hm_id: item.id,
        project_id: item.project.id,
        project_color: item.project.color
      };
      return newData;
    });
};

const HistoryItem: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const formState = useSelector((state: IAppState) => state.dataHeatmapState);
  const editState = useSelector((state: IAppState) => state.editHeatmapState);
  const editDataHeatmapState = useSelector((state: IAppState) => state.editHeatmapState);
  const { historyList, handleDelete, handleOpenModalTemplate } = props;

  const [isShowModalEditHeatmap, setIsShowModalEditHeatmap] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [disableSubmitModal, setDisableSubmitModal] = useState(false);
  const data = handleData(historyList);
  const groupedData = data.reduce((acc, obj) => {
    const existingGroup = acc.find((item) => item.itemDate === obj.date);
    if (existingGroup) {
      existingGroup.itemObject.push(obj);
    } else {
      acc.push({ itemDate: obj.date, itemObject: [obj] });
    }
    return acc;
  }, []);

  useEffect(() => {
    localStorage.setItem('groupedDataHeatMap', JSON.stringify(groupedData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(groupedData)]);

  const menu = (dataMenu: any) => {
    const onCopy = () => {
      const dataItem = { ...formState };
      dataItem.items = dataMenu.itemObject.map(
        (item: {
          project_id: number;
          activity: string;
          hour: number;
          project: string;
          project_color: string;
        }) => {
          const index = formState.items.findIndex(
            (project) => project.project_id === item.project_id
          );
          if (index >= 0) {
            return {
              ...dataItem.items[index],
              content: item.activity,
              hours: item.hour
            };
          }
          return {
            project_id: item.project_id,
            project_name: item.project,
            project_color: item.project_color,
            content: item.activity,
            hours: item.hour
          };
        }
      );
      dataItem.activeTemplate = dataItem.items.map((item) => {
        return item.project_id;
      }) as any;
      dispatch(activeTemplateAction(dataItem.activeTemplate));
      dispatch(updateDataHeatmap({ ...dataItem }));
      saveHeatmap({ id: 'new', date: '', items: dataItem.items });
      message.success('Copied!');
    };
    const onEdit = () => {
      const editItem = { ...editState };
      editItem.itemsEdit = dataMenu.itemObject.map((item) => {
        return {
          hm_id: item.hm_id,
          project_id: item.project_id,
          project_name: item.project,
          project_color: item.project_color,
          content: item.activity,
          hours: item.hour
        };
      });
      dispatch(editDataHeatmap({ ...editItem, dateEdit: dataMenu.itemDate }));
      dispatch(setEditFail(false));
      setIsShowModalEditHeatmap(true);
      dispatch(setDateLocalEdit(dataMenu.itemDate));
    };
    const onDelete = () => {
      handleDelete(dataMenu.itemObject, true);
    };
    return (
      <Menu>
        <Menu.Item
          style={{
            padding: '4px 0',
            borderRadius: 4,
            backgroundColor: '#fff'
          }}>
          <L.OptionLabel onClick={onCopy} style={{ padding: '8px 10px' }}>
            <Col
              style={{
                height: '16px',
                padding: 0,
                lineHeight: '14px',
                display: 'flex',
                gap: '8px',
                alignItems: 'flex-end'
              }}>
              <img className={'icon-more'} width={16} src={icons.IconCopyHeatmap} alt="" />
              <span>Copy</span>
            </Col>
          </L.OptionLabel>
          <L.OptionLabel onClick={onEdit} style={{ padding: '8px 10px' }}>
            <Col
              style={{
                height: '16px',
                padding: 0,
                lineHeight: '14px',
                display: 'flex',
                gap: '8px',
                alignItems: 'flex-end'
              }}>
              <img className={'icon-edit'} width={16} src={icons.IconEditHeatmap} alt="" />
              <span>Edit</span>
            </Col>
          </L.OptionLabel>
          <L.OptionLabel onClick={onDelete} style={{ padding: '8px 10px' }}>
            <Col
              style={{
                height: '16px',
                padding: 0,
                lineHeight: '14px',
                display: 'flex',
                gap: '8px',
                alignItems: 'flex-end'
              }}>
              <img className={'icon-delete'} width={16} src={icons.IconDeleteHeatmap} alt="" />
              <span>Delete</span>
            </Col>
          </L.OptionLabel>
        </Menu.Item>
      </Menu>
    );
  };

  const Title = ({ data }: any) => {
    const onCopy = () => {
      const dataItem = { ...formState };
      const index = dataItem.items.findIndex((e) => e.project_id == data.project_id);
      if (index >= 0) {
        dataItem.items[index] = {
          ...dataItem.items[index],
          content: data.activity,
          hours: data.hour
        };
      } else {
        const item = {
          project_id: data.project_id,
          project_name: data.project,
          project_color: data.project_color,
          content: data.activity,
          hours: data.hour
        };
        dataItem.items.unshift(item);
      }
      const ids = [];
      ids.push(data.project_id as never);
      dispatch(activeTemplateAction(ids));
      dispatch(updateDataHeatmap({ ...dataItem }));
      saveHeatmap({ id: 'new', date: '', items: dataItem.items });
      message.success('Copied!');
    };
    const onDelete = () => {
      handleDelete([{ ...data }], false);
    };
    return (
      <Row align={'middle'}>
        <Col style={{ flexGrow: 1 }}>
          <Row align={'middle'}>
            <T.ProjectTitle
              paddingLeft="16px"
              fontSize="14px"
              fontColor="#1A1A1A"
              color={data.color}
              regular>{`[${data.id}] ${data.project}`}</T.ProjectTitle>
          </Row>
        </Col>
        <Col>
          <HistoryIcon>
            <I.Image
              src={icons.IconDeleteHeatmap}
              mrTop="0"
              w={16}
              onClick={onDelete}
              style={{ verticalAlign: 'text-top', cursor: 'pointer' }}
            />
            <I.Image
              onClick={onCopy}
              src={icons.IconCopyHeatmap}
              mrTop="0"
              w={15}
              style={{ verticalAlign: 'text-top', cursor: 'pointer' }}
            />
            <L.ColorLabel
              height={17}
              color={data.color}
              fontSize="10.5px"
              borderRadius="2px"
              fontColor="#F9F9F9"
              style={{ width: 'fit-content', padding: '0px 6px', whiteSpace: 'nowrap' }}>
              {data.hour} hours
            </L.ColorLabel>
          </HistoryIcon>
        </Col>
      </Row>
    );
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const updateListProjects = async (listProjects) => {
    const listDataHeatmapId = editDataHeatmapState.itemsEdit.map((item) => item.project_id);
    const temp = { ...editDataHeatmapState };
    setDisableSubmitModal(true);
    if (!listProjects || !listProjects.length) {
      message.error('No projects.');
      setDisableSubmitModal(false);
      return;
    }
    const listProjectsReverse = listProjects.reverse();
    if (listProjectsReverse) {
      listProjectsReverse.forEach((project) => {
        if (listDataHeatmapId.includes(project.id)) {
          const index = editDataHeatmapState.itemsEdit.findIndex(
            (item) => item.project_id === project.id
          );
          temp.itemsEdit[index] = {
            ...temp.itemsEdit[index],
            content: '',
            hours: null as any
          };
        } else {
          temp.itemsEdit.unshift({
            project_id: project.id,
            project_name: project.name,
            project_color: project.color,
            content: '',
            hours: null as any
          });
        }
      });
      dispatch(editDataHeatmap({ ...temp }));
      saveHeatmap({
        id: dayjs(editDataHeatmapState.dateLocal).format('DD-MM-YYYY'),
        date: temp.dateEdit,
        items: temp.itemsEdit
      });
      setDisableSubmitModal(false);
      closeModal();
    }
  };

  return (
    <HistoryList>
      {groupedData &&
        groupedData.map((item) => (
          <div key={item.itemDate} className="history-list">
            <T.FlexText>
              <T.Date>{dayjs(item.itemDate).format('ddd, MMM DD, YYYY')}</T.Date>
              <Dropdown
                overlay={() => menu(item)}
                overlayClassName={'history-copy'}
                getPopupContainer={(trigger: any) => trigger.parentNode}>
                <I.Image
                  src={seeMoreImage}
                  w={18}
                  mrTop="0px"
                  h={18}
                  style={{ cursor: 'pointer' }}
                />
              </Dropdown>
            </T.FlexText>
            <List
              style={{
                padding: '12px',
                backgroundColor: '#F9F9F9',
                borderRadius: '4px'
              }}
              itemLayout={'horizontal'}
              dataSource={item.itemObject}
              renderItem={(i: any) => (
                <List.Item style={{ border: 'none', padding: '0px' }}>
                  <Wrapper isHover style={{ padding: '0px', bg: '#F9F9F9' }}>
                    <List.Item.Meta
                      style={{ backgroundColor: '#F9F9F9' }}
                      avatar={null}
                      title={<Title data={i}></Title>}
                      description={
                        <T.Description
                          style={{ paddingTop: '2px' }}
                          dangerouslySetInnerHTML={{
                            __html: handleBreakline(i.activity)
                          }}
                        />
                      }
                    />
                  </Wrapper>
                </List.Item>
              )}
            />
          </div>
        ))}
      {openModal && (
        <FavoriteProjectsModal
          title="Choose which projects to post"
          isOpen={openModal}
          closeModal={closeModal}
          disable={disableSubmitModal}
          onSubmit={updateListProjects}
          listDefaultProjects={[]}
          listHiddenProjects={editDataHeatmapState.itemsEdit.map((item) => item.project_id)}
        />
      )}
      {isShowModalEditHeatmap && (
        <ModalEditHeatmap
          isShow={isShowModalEditHeatmap}
          onCancel={() => setIsShowModalEditHeatmap(false)}
          handleOpenModalAddProject={() => setOpenModal(true)}
          handleOpenModalTemplate={handleOpenModalTemplate}
        />
      )}
    </HistoryList>
  );
};

export default HistoryItem;
